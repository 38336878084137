<template>
    <!-- 域名列表页 -->
    <div class="dmLists">
        <div class="dlBox">
            <!-- 搜索框 -->
            <div class="dlbTop">
                <div class="dtSearch">
                    <div class="dsBox1"><input type="text" /></div>
                    <button class="dsBox2">威有一下</button>
                </div>
            </div>
            <!-- 列表 -->
            <div class="dlbList">
                <ul>
                    <li class="dlLi1">
                        <dl>
                            <dt>域名</dt>
                            <dt>
                                <p>剩余时间</p>
                                <div class="dlIcon">
                                    <img
                                        class="diImg1"
                                        v-show="showIcon === 1"
                                        src="http://iv.okvu.cn/vugw/img/vu818.png"
                                        alt=""
                                        @click="showIcons(2, (selectType2 = 1))"
                                    />
                                    <img
                                        class="diImg2"
                                        v-show="showIcon === 2"
                                        src="http://iv.okvu.cn/vugw/img/vu818.png"
                                        alt=""
                                        @click="showIcons(3, (selectType2 = 2))"
                                    />
                                    <img
                                        class="diImg3"
                                        v-show="showIcon === 3"
                                        src="http://iv.okvu.cn/vugw/img/vu818.png"
                                        alt=""
                                        @click="showIcons(1, (selectType2 = 0))"
                                    />
                                </div>
                            </dt>
                            <dt>
                                <p>价格</p>
                                <div class="dlIcon">
                                    <img
                                        class="diImg1"
                                        v-show="showIcon2 === 1"
                                        src="http://iv.okvu.cn/vugw/img/vu818.png"
                                        alt=""
                                        @click="showIcons2(2, (selectType = 1))"
                                    />
                                    <img
                                        class="diImg2"
                                        v-show="showIcon2 === 2"
                                        src="http://iv.okvu.cn/vugw/img/vu818.png"
                                        alt=""
                                        @click="showIcons2(3, (selectType = 2))"
                                    />
                                    <img
                                        class="diImg3"
                                        v-show="showIcon2 === 3"
                                        src="http://iv.okvu.cn/vugw/img/vu818.png"
                                        alt=""
                                        @click="showIcons2(1, (selectType = 0))"
                                    />
                                </div>
                            </dt>
                            <dt>交易类型</dt>
                            <dt>操作</dt>
                        </dl>
                    </li>
                    <li class="dlLi" v-for="item in flDatas" :key="item.id">
                        <dl>
                            <dt>{{ item.name }}</dt>
                            <dt>{{ item.times }}</dt>
                            <dt>威有{{ item.prices }}</dt>
                            <dt>{{ item.texts }}</dt>
                            <dt>
                                <div class="dtB">查看详情</div>
                                <div class="dtB">立即想要</div>
                            </dt>
                        </dl>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DmLists",
    data() {
        return {
            count: 10,
            showIcon: 1,
            showIcon2: 1,
            dlData: [
                {
                    id: 1,
                    name: "0.bj.cn",
                    times: 4,
                    prices: 55983,
                    texts: "一口价（优选）",
                },
                {
                    id: 2,
                    name: "1.bj.cn",
                    times: 2,
                    prices: 22283,
                    texts: "一口价（优选）",
                },
                {
                    id: 3,
                    name: "3.bj.cn",
                    times: 3,
                    prices: 14983,
                    texts: "一口价（优选）",
                },
                {
                    id: 4,
                    name: "4.bj.cn",
                    times: 1,
                    prices: 54583,
                    texts: "一口价（优选）",
                },
                {
                    id: 5,
                    name: "5.bj.cn",
                    times: 5,
                    prices: 15563,
                    texts: "一口价（优选）",
                },
            ],
            // 判断顺序
            selectType: 0,
            selectType2: 0,
        };
    },
    computed: {
        // 价格升序降序
        flDatas() {
            let arr = this.dlData;
            if (this.selectType != 0) {
                arr.sort((a, b) => {
                    return this.selectType === 1 ? b.prices - a.prices : a.prices - b.prices;
                });
            } else if (this.selectType2 != 0) {
                arr.sort((a2, b2) => {
                    return this.selectType2 === 1 ? b2.times - a2.times : a2.times - b2.times;
                });
                // console.log(arr);
            }
            return arr;
        },
    },
    mounted() {
        window.addEventListener("scroll", this.scrollDmList); //绑定页面滚动事件
    },
    methods: {
        showIcons(i) {
            this.showIcon = i;
        },
        showIcons2(i) {
            this.showIcon2 = i;
        },
        // 下拉加载更多
        scrollDmList(e) {
            let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
            // console.log(top);
            if (top >= 100) {
                setTimeout(() => {
                    this.count = 20;
                }, 1000);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.dmLists {
    width: 100%;
    min-height: 600px;
    margin-top: 78px;
    .dlBox {
        max-width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 搜索框
        .dlbTop {
            width: 80%;
            height: 100px;
            margin: 0 auto;
            margin-top: 50px;
            .dtSearch {
                width: 100%;
                height: 50px;
                background-color: #fff;
                box-shadow: 1px 1px 10px 1px gainsboro;
                border-radius: 10px;
                .dsBox1 {
                    width: 85%;
                    height: 100%;
                    float: left;
                    input {
                        width: 95%;
                        height: 48px;
                        border: none;
                        outline: none;
                        font-size: 15px;
                        padding-left: 20px;
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                }
                .dsBox2 {
                    width: 15%;
                    height: 50px;
                    float: left;
                    border: none;
                    color: white;
                    font-size: 18px;
                    cursor: pointer;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    outline: none;
                }
                .dsBox2:hover {
                    background: linear-gradient(to right, #e1aef8, #a977f5);
                }
            }
        }
        .dlbList {
            width: 100%;
            min-height: 80px;
            margin-bottom: 40px;
            background-color: #fff;

            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .dlLi1,
                .dlLi {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 12px;
                    dl {
                        width: 90%;
                        height: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-around;
                        dt {
                            width: 197px;
                            cursor: pointer;
                            p {
                                float: left;
                            }
                            .dlIcon {
                                width: 12px;
                                height: 40px;
                                float: left;
                                position: relative;
                                overflow: hidden;
                                .diImg1 {
                                    width: 1000px;
                                    height: 2000px;
                                    position: absolute;
                                    top: -70px;
                                    right: -104px;
                                }
                                .diImg2 {
                                    width: 1000px;
                                    height: 2000px;
                                    position: absolute;
                                    top: -70px;
                                    right: -90px;
                                }
                                .diImg3 {
                                    width: 1000px;
                                    height: 2000px;
                                    position: absolute;
                                    top: -70px;
                                    right: -80px;
                                }
                            }
                            .dtB {
                                width: 82px;
                                height: 29px;
                                margin-top: 10px;
                                line-height: 30px;
                                text-align: center;
                                font-size: 12px;
                                float: left;
                                border-radius: 5px;
                                box-shadow: 1px 1px 10px 1px gainsboro;
                                cursor: pointer;
                                background-color: white;
                            }
                            .dtB:nth-child(2) {
                                margin-left: 20px;
                                background-color: #108cee;
                                color: white;
                            }
                            .dtB:nth-child(1):hover {
                                color: #108cee;
                            }
                        }
                        dt:nth-child(1) {
                            width: 350px;
                        }
                    }
                }
                .dlLi1 {
                    background-color: #eee;
                    font-size: 14px;
                }
                .dlLi {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    border-bottom: 1px solid #ebebeb;
                    font-size: 17px;
                    dl dt:nth-child(3) {
                        color: #f46653;
                    }
                }
                .dlLi:hover {
                    background-color: rgb(231, 248, 255);
                }
            }
        }
    }
}
</style>
